import { useLogger } from '@hyperclap/ui';
import { useEffect, useState } from 'react';

import { useApi, useAuth } from '@hooks';

export const useCurrentUser = () => {
    const logger = useLogger({ target: 'useCurrentUser', showTimestamp: true });

    const { authToken, clearTokens } = useAuth();

    const {
        users: {
            useCurrentUserQuery,
        },
        auth: {
            useLogoutMutation,
        },
    } = useApi();

    const {
        data: currentUser,
        isLoading,
        isFetching,
        isError,
        isSuccess,
        error,
        refetch: refetchCurrentUser,
    } = useCurrentUserQuery(void(0), { skip: !authToken });

    const [logoutUser] = useLogoutMutation();

    const [noCurrentUser, setNoCurrentUser] = useState(false);

    const logout = async () => {
        await logoutUser();
        logger.debug(`User logged out, current tokens will be removed`);
        clearTokens();
    };

    useEffect(() => {
        setNoCurrentUser((!isLoading && !isFetching && isSuccess && !currentUser) || isError);

        if (isError && error && (error as { status: number }).status === 403) {
            logger.debug(`Current token is invalid and will be removed`);
            clearTokens();
        }
    }, [currentUser, isLoading, isFetching, isError, isSuccess]);

    useEffect(() => {
        if (!authToken) {
            setNoCurrentUser(true);
        }
    }, []);

    return {
        currentUser,
        noCurrentUser,
        state: {
            isLoading: isLoading || isFetching,
            isError,
            isSuccess,
        },
        refetchCurrentUser,
        logout,
    };
};
