import { Column, ERowAlignment, ERowJustification, Row } from '@hyperclap/ui';
import React, { PropsWithChildren } from 'react';

import { TApp } from '@hooks';
import { DesktopPage, LeftMenu } from '@views';

import { Footer, Header } from '../../fragments';

interface IContentWithLeftMenuProps extends TApp {}

export const ContentWithLeftMenu = (props: PropsWithChildren<IContentWithLeftMenuProps>) => {
    return (
        <DesktopPage header={<Header {...props}/>} footer={<Footer {...props}/>}>
            <Row grow alignment={ERowAlignment.STRETCH} justification={ERowJustification.STRETCH}>
                <LeftMenu {...props}/>
                <Column grow>
                    {props.children}
                </Column>
            </Row>
        </DesktopPage>
    );
};
