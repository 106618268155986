import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useNavigator = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    const goToPath = (path: string, options?: NavigateOptions) => navigate(path, options);

    const goHome = () => navigate('/');

    return {
        goBack,
        goHome,
        goToPath,
    };
};
