import { Column } from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';
import { UnderConstruction } from '@views';

import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {

    } = props;

    return (
        <Column padding={20} grow className={s.view}>
            <UnderConstruction />
        </Column>
    );
};
