import {
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentSize,
    EOrientation,
    ERelativePosition,
    ERowJustification,
    Icon,
    Label,
    Row,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useState } from 'react';
import { useMatch } from 'react-router-dom';

import {
    IconChevronsLeftLine,
    IconChevronsRightLine,
    IconPieChartLine,
    IconPlayLine,
    IconSlidersLine,
} from '@assets/images/svg';
import { Menu, MenuItem, MenuItemProps } from '@components';
import { TApp } from '@hooks';

import s from './LeftMenu.scss';

interface ILeftMenuProps extends TApp {}

const defaultMenuItemProps: MenuItemProps<string> = {
    className: s.leftSideMenuItem,
    iconClassName: s.leftSideMenuItemIcon,
    iconPosition: ERelativePosition.LEFT,
};

export const LeftMenu = (props: ILeftMenuProps) => {
    const {
        navigation: {
            goToPath,
        },
        translation: {
            t,
        },
    } = props;

    const [isCollapsed, setIsCollapsed] = useState(false);

    const menuStructure: MenuItemProps<string>[] = [
        {
            active: !!useMatch('/statistics'),
            caption: t('app.main-menu.statistics'),
            icon: <IconPieChartLine/>,
            tag: '/statistics',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/settings'),
            caption: t('app.main-menu.settings'),
            icon: <IconSlidersLine/>,
            tag: '/settings',
            ...defaultMenuItemProps,
        },
        {
            active: !!useMatch('/actions'),
            caption: t('app.main-menu.actions'),
            icon: <IconPlayLine/>,
            tag: '/actions',
            ...defaultMenuItemProps,
        },
    ];

    const onMenuItemClick = (tag?: string) => {
        if (tag) {
            goToPath(tag);
        }
    };

    const menuItems = menuStructure.map((itemProps, idx) => (
        <MenuItem key={idx} {...itemProps} onClick={onMenuItemClick}/>
    ));

    return (
        <Column
            alignment={EColumnAlignment.STRETCH} justification={EColumnJustification.TOP}
            className={cn(
                s.leftMenu,
                {
                    [s.leftMenuCollapsed]: isCollapsed,
                },
            )}
        >
            <Icon
                icon={isCollapsed ? <IconChevronsRightLine/> : <IconChevronsLeftLine/>}
                size={EComponentSize.SMALL}
                className={s.collapseButton}
                onClick={() => setIsCollapsed((prev) => !prev)}
            />
            <Column grow padding={{ top: 11, bottom: 10 }}>
                <Menu orientation={EOrientation.VERTICAL}>
                    {menuItems}
                </Menu>
            </Column>
        </Column>
    );
};
