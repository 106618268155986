/** One second (in milliseconds) */
export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_YEAR = ONE_DAY * 365;

/** The locale that is used to format date/time */
export const DATE_FORMAT_LOCALE = 'ru';

/** Localized short date format (2-digits year) */
export const DATE_FORMAT_SHORT: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
};

/** Localized long date format (4-digits year) */
export const DATE_FORMAT_LONG: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};

/** Localized short time format (2-digits hours, minutes, seconds) */
export const TIME_FORMAT_TIMESTAMP: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};
