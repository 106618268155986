// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnderConstruction__bar--FWDjO {
  background: repeating-linear-gradient(-45deg, #cfa600, #cfa600 20px, #000000 20px, #000000 40px);
}

.UnderConstruction__label--pNVqN {
  text-shadow: 0 0 15px rgb(0, 0, 0), 0 0 10px rgb(0, 0, 0), 0 0 5px rgb(0, 0, 0);
}
.UnderConstruction__label--pNVqN div {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/base/views/fragments/UnderConstruction/UnderConstruction.scss"],"names":[],"mappings":"AAAA;EACI,gGAAA;AACJ;;AAQA;EAII,+EAAA;AARJ;AAKI;EACI,YAAA;AAHR","sourcesContent":[".bar  {\r\n    background: repeating-linear-gradient(\r\n            -45deg,\r\n            #cfa600,\r\n            #cfa600 20px,\r\n            #000000 20px,\r\n            #000000 40px\r\n    );\r\n}\r\n\r\n.label {\r\n    div {\r\n        color: white;\r\n    }\r\n    text-shadow: 0 0 15px rgba(0, 0, 0, 1), 0 0 10px rgba(0, 0, 0, 1), 0 0 5px rgba(0, 0, 0, 1);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `UnderConstruction__bar--FWDjO`,
	"label": `UnderConstruction__label--pNVqN`
};
export default ___CSS_LOADER_EXPORT___;
