export interface IFlags {
    isAdmin?: boolean;
    isModer?: boolean;
    isScout?: boolean;
}

export interface IUser {
    id: string;
    name: string;
    username: string;
    avatar: string;
    flags: IFlags;
    createdAt: string;
}
