import { createSlice } from '@reduxjs/toolkit';

import { initialAppState } from '@typings';

const appSlice = createSlice({
    name: 'app',
    initialState: initialAppState,
    reducers: {},
});

export const appSliceActions = appSlice.actions;
export const appSliceReducer = appSlice.reducer;
