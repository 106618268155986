import { Column } from '@hyperclap/ui';
import React from 'react';

import { TApp } from '@hooks';

interface IMobileProps extends TApp {}

export const Mobile = (props: IMobileProps) => {
    const {

    } = props;

    return (
        <Column></Column>
    );
};
