import {
    Button,
    Column,
    Divider,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Label,
    Row,
    ScrollableArea,
    Selector,
} from '@hyperclap/ui';
import React from 'react';

import { StatisticsMode, TApp } from '@hooks';

import { StatisticRecord } from './components';
import s from './Desktop.scss';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        statistics: {
            currentDayStats,
            currentMonthStats,
            dailyStatistic,
            isDailyEndReached,
            isMonthlyEndReached,
            monthlyStatistic,
            mode,
            setMode,
            loadNextDailyPage,
            loadNextMonthlyPage,
        },
        translation: {
            t: translate,
        },
    } = props;

    const t = (text: string) => translate(`app.statistics.${text}`);

    const rows = mode === StatisticsMode.DAILY
        ? dailyStatistic?.map((r, idx) => (
            <StatisticRecord data={r} key={idx}/>
        ))
        : monthlyStatistic?.map((r, idx) => (
            <StatisticRecord data={r} key={idx} showAsMonth/>
        ));

    const PERIODS = [
        { caption: t('periods.day'), value: StatisticsMode.DAILY },
        // { caption: t('periods.week'), value: StatisticsMode.WEEKLY },
        { caption: t('periods.month'), value: StatisticsMode.MONTHLY },
    ];

    const onNextPageClick = () => {
        switch (mode) {
            case StatisticsMode.DAILY: {
                loadNextDailyPage();
                break;
            }

            case StatisticsMode.MONTHLY: {
                loadNextMonthlyPage();
                break;
            }
        }
    };

    return (
        <Column grow padding={10} className={s.view}>
            <Row columnGap={15} justification={ERowJustification.SPACE_BETWEEN}>
                <Column grow>
                    <Label caption={t('title')} size={EComponentSize.MEDIUM} useBoldFont/>
                </Column>
                <Column>
                    {t('period')}
                </Column>
                <Column>
                    <Selector
                        valuesList={PERIODS}
                        defaultSelectionIndex={mode}
                        hideListOnSelect
                        className={s.selector}
                        onSelectionChange={(item) => setMode(item.value)}/>
                </Column>
                <Column>
                    <Button
                        caption={t('load-more')}
                        disabled={
                            (mode === StatisticsMode.DAILY && isDailyEndReached) ||
                            (mode === StatisticsMode.MONTHLY && isMonthlyEndReached)
                        }
                        onClick={onNextPageClick}
                    />
                </Column>
            </Row>

            <Divider/>

            <Row grow alignment={ERowAlignment.TOP}>
                <ScrollableArea className={s.scrollableContainer} contentClassName={s.scrollableContainerContent}>
                    <table className={s.table}>
                        <thead>
                            <tr>
                                <td>
                                    {mode === StatisticsMode.DAILY ? 'Date' : 'Month'}
                                </td>
                                <td>
                                    {'New Users'}
                                </td>
                                <td>
                                    {'Total Users'}
                                </td>
                                <td>
                                    {'New Payers'}
                                </td>
                                <td>
                                    {'Total Payers'}
                                </td>
                                <td>
                                    {'Payers'}
                                </td>
                                <td>
                                    {'New Streamers'}
                                </td>
                                <td>
                                    {'Total Streamers'}
                                </td>
                                <td>
                                    {'Active Streamers'}
                                </td>
                                <td>
                                    {'AS%'}
                                </td>
                                <td>
                                    {'Streamers Hits'}
                                </td>
                                <td>
                                    {'Hits / AS'}
                                </td>
                                <td>
                                    {'Sticker Open'}
                                </td>
                                <td>
                                    {'Sticker Sent'}
                                </td>
                                <td>
                                    {'FS Sent'}
                                </td>
                                <td>
                                    {'MP'}
                                </td>
                                <td>
                                    {'Sent / Open'}
                                </td>


                                <td>
                                    {'Senders'}
                                </td>
                                <td>
                                    {'Sent / Senders'}
                                </td>
                                <td>
                                    {'Purchase'}
                                </td>
                                <td>
                                    {'Spent'}
                                </td>
                                <td>
                                    {'Balance'}
                                </td>
                                <td>
                                    {'ARPAS'}
                                </td>
                                <td>
                                    {'ARPPU'}
                                </td>
                                <td>
                                    {'Revenue'}
                                </td>
                                <td>
                                    {'Profit'}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {mode === StatisticsMode.DAILY && currentDayStats &&
                                <StatisticRecord data={currentDayStats} key={-1}/>
                            }

                            {mode === StatisticsMode.MONTHLY && currentMonthStats &&
                                <StatisticRecord data={currentMonthStats} key={-1} showAsMonth/>
                            }

                            {rows}
                        </tbody>
                    </table>
                </ScrollableArea>
            </Row>
        </Column>
    );
};
