export enum SortOrder {
    ASC,
    DESC,
}

export const sortObjects = <T>(arr: Array<T>, prop: keyof T, order: SortOrder = SortOrder.ASC) => {
    return order === SortOrder.ASC
        ? arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
        : arr.sort((a, b) => a[prop] > b[prop] ? -1 : a[prop] === b[prop] ? 0 : 1);
};

export const mergeObjectArrays = <T>(arr1: Array<T>, arr2: Array<T>, key: keyof T) => {
    const map = new Map<string, T>();

    arr1.forEach((element) => map.set(element[key] as string, element));
    arr2.forEach((element) => map.set(element[key] as string, element));

    return Array.from(map.values());
};
