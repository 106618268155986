export interface StatisticData {
    day: number;
    newUsers: number;
    totalUsers: number;
    newStreamers: number;
    totalStreamers: number;
    activeStreamers: number;
    streamerPageHits: number;
    sendAlertPopups: number;
    uniquePayersCount: number;
    uniqueSendersCount: number;
    purchasedCurrencyValue: number;
    spentCurrencyValue: number;
    totalCurrencyInWallets: number;
    sales: number;
    fullscreen: number;
    revenue: number;
    profit: number;
    memePartyCount: number;
    newPayersCount: number;
    totalPayersCount: number;
}
