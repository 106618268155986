import { Column, EColumnAlignment } from '@hyperclap/ui';
import React, { PropsWithChildren } from 'react';

import { DesktopPage } from '@views';

export const Basic = ({ children }: PropsWithChildren) => (
    <DesktopPage>
        <Column grow alignment={EColumnAlignment.STRETCH}>
            {children}
        </Column>
    </DesktopPage>
);
