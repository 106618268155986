/** The name (key in a browser local storage) of the authorization token */
export const AUTH_TOKEN_NAME = 'accessToken'; // TODO: Change to q

/** The name (key in a browser local storage) of the refresh token */
export const REFRESH_TOKEN_NAME = 'refreshToken'; // TODO: Change to w

/** The name (key in a browser query) of the user banned flag */
export const USER_BANNED_NAME = 'banned';

/** The name (key in a browser query) of the banned userId */
export const USER_BANNED_ID_NAME = 'bid';
