/** IMPORTANT!! Please, use alphabetical order when placing new image export */

/** Icons */
export { default as IconChevronsLeftLine } from './chevrons-left.svg';
export { default as IconChevronsRightLine } from './chevrons-right.svg';
export { default as IconCloseLine } from './close-line.svg';
export { default as IconGlobeLine } from './globe-line.svg';
export { default as IconKeyLine } from './key.svg';
export { default as IconLogInLine } from './log-in.svg';
export { default as IconLogoutLine } from './logout-line.svg';
export { default as IconPieChartLine } from './pie-chart.svg';
export { default as IconPlayLine } from './play.svg';
export { default as IconSlidersLine } from './sliders.svg';
export { default as IconUploadLine } from './upload-line.svg';
export { default as IconUserLine } from './user.svg';
