import { ModalProvider, ThemeContext } from '@hyperclap/ui';
import cn from 'classnames';
import React, { ReactElement, useContext } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { AuthProvider, NotificationProvider } from '@contexts';

import s from './App.scss';

interface IAppProps {
    login: ReactElement;
    routing: Array<RouteObject>;
}

export const App = (props: IAppProps) => {
    const { login, routing } = props;
    const { theme } = useContext(ThemeContext);
    const routes = useRoutes(routing);

    return (
        <AuthProvider redirectUnauthorizedTo={login}>
            <ModalProvider>
                <NotificationProvider>
                    <div className={cn(s.root, `theme-${theme}`)}>
                        {routes}
                    </div>
                </NotificationProvider>
            </ModalProvider>
        </AuthProvider>
    );
};
