import { Logger } from '@hyperclap/ui';

import { IUser, TOptional } from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'UsersApi', showTimestamp: true });
logger.trace(`Configure Users API`);

/** Invites API provides methods are related users (streamers, viewers_) data */
export const usersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Users_CurrentUser',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({

            /** Loads current user data */
            currentUser: build.query<IUser, void>({
                query: () => ({
                    url: 'user/current',
                    method: 'GET',
                }),
                providesTags: ['Users_CurrentUser'],
            }),

            /** Gets a user by username */
            getUserByName: build.query<IUser, TOptional<string>>({
                query: (username: string) => ({
                    url: 'user/find',
                    method: 'POST',
                    body: { username },
                }),
            }),

            /** Searches a streamer by name (suggestion list) */
            findStreamer: build.query<IUser[], string>({
                query: (value: string) => ({
                    url: 'user/find/streamer',
                    method: 'POST',
                    body: { value },
                }),
            }),
        }),
    });
