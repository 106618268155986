import { Column, ITextFieldAction, Label, TextField } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';

import { IconKeyLine, IconLogInLine, IconUserLine } from '@assets/images/svg';

interface ILoginFormProps {
    usernameText: string;
    passwordText: string;
    onLogin: (username: string, password: string) => void;
}

interface ILoginFormState {
    username: string;
    password: string;
    loginButtonDisabled: boolean;
}

const DEFAULT_LOGIN_FORM_STATE: ILoginFormState = {
    username: '',
    password: '',
    loginButtonDisabled: false,
};

export const LoginForm = (props: ILoginFormProps) => {
    const {
        usernameText,
        passwordText,
        onLogin,
    } = props;

    const [loginFormState, setLoginFormState] = useState<ILoginFormState>(DEFAULT_LOGIN_FORM_STATE);

    const onLoginButtonClick = (action: ITextFieldAction) => {
        if (action.name === 'ENTER') {
            onLogin(loginFormState.username, loginFormState.password);
        }
    };

    useEffect(() => {
        setLoginFormState((prev) => ({
            ...prev,
            loginButtonDisabled: !loginFormState.username && !loginFormState.password,
        }));
    }, [loginFormState.username, loginFormState.password]);

    return (
        <Column rowGap={10} width={300}>
            <TextField
                placeholder={usernameText}
                tag={<IconUserLine/>}
                onChanged={(val) => setLoginFormState((prev) => ({ ...prev, username: val }))}
            />
            <TextField
                placeholder={passwordText}
                tag={<IconKeyLine/>}
                type={'password'}
                onChanged={(val) => setLoginFormState((prev) => ({ ...prev, password: val }))}
                actions={[
                    { icon: <IconLogInLine/>, name: 'ENTER', keyboardKeys: ['Enter'] },
                ]}
                onAction={onLoginButtonClick}
            />
        </Column>
    );
};
