import { useLogger } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React, { createContext, ReactElement, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from '@common';
import { IAuthData, TAuthContext } from 'src/base/typings';

const initialAuthData: IAuthData = {
    authToken: '',
    refreshToken: '',
};

interface IAuthProviderProps {
    redirectUnauthorizedTo: ReactElement;
}

export const AuthContext = createContext<TAuthContext>({
    ...initialAuthData,
    clearTokens: noop,
    updateToken: noop,
    redirectUnauthorizedTo: '',
});

export const AuthProvider = ({ children, redirectUnauthorizedTo }: React.PropsWithChildren<IAuthProviderProps>) => {
    const logger = useLogger({ target: AuthProvider.name, showTimestamp: true });
    const [query] = useSearchParams();
    const authDataRef = useRef<IAuthData>({ ...initialAuthData });

    const authToken = query.get(AUTH_TOKEN_NAME);

    if (authToken) {
        localStorage.setItem(AUTH_TOKEN_NAME, authToken);
        authDataRef.current.authToken = authToken;
    }

    const refreshToken = query.get(REFRESH_TOKEN_NAME);

    if (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
        authDataRef.current.refreshToken = refreshToken;
    }

    const providedAuthToken = localStorage.getItem(AUTH_TOKEN_NAME);

    if (providedAuthToken) {
        authDataRef.current.authToken = providedAuthToken;
    }

    const clearTokens = () => {
        localStorage.removeItem(AUTH_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
        authDataRef.current = { ...initialAuthData };
    };

    const updateToken = (accessToken?: string, refreshToken?: string) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            authDataRef.current.authToken = accessToken;
        }

        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
            authDataRef.current.refreshToken = refreshToken;
        }
    };

    const value: TAuthContext = {
        ...authDataRef.current,
        clearTokens,
        updateToken,
        redirectUnauthorizedTo,
    };

    useEffect(() => {
        logger.trace(`Build authorization context`);

        return () => logger.trace(`Destroy authorization context`);
    }, []);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
