import { EComponentSize, ERowJustification, Label, Row } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './UnderConstruction.scss';

export const UnderConstruction = () => {
    const { t } = useTranslation();

    return (
        <Row className={s.bar} justification={ERowJustification.CENTER}>
            <Label padding={15} caption={t('app.under-construction')} useBoldFont size={EComponentSize.LARGE} className={s.label}/>
        </Row>
    );
};
