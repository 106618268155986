import React from 'react';
import { Outlet } from 'react-router-dom';

import { useApp } from '@hooks';

import { ContentWithLeftMenu } from '../../templates';

export const AppPage = () => {
    const app = useApp();

    return (
        <ContentWithLeftMenu {...app}>
            <Outlet context={app}/>
        </ContentWithLeftMenu>
    );
};
