import { Column, EColumnAlignment, EColumnJustification, EComponentSize, Label } from '@hyperclap/ui';
import React from 'react';

import { useApp } from '@hooks';
import { LoginForm } from '@views/fragments';

import s from './Login.scss';
import { Basic } from '../../templates';


export const Login = () => {
    const {
        login: {
            login,
        },
        translation: {
            t,
        },
    } = useApp();

    return (
        <Basic>
            <Column
                alignment={EColumnAlignment.CENTER} justification={EColumnJustification.CENTER} grow
                className={s.view}
            >
                <Label padding={15} caption={t('login.prompt')} size={EComponentSize.MEDIUM} useBoldFont/>
                <LoginForm
                    usernameText={t('login.username')}
                    passwordText={t('login.password')}
                    onLogin={login}
                />
            </Column>
        </Basic>
    );
};
