import { baseApi } from './base.api';

export const authApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Users_CurrentUser',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            login: build.query<Record<string, string>, { username: string, password: string }>({
                query: (credentials: { username: string, password: string }) => ({
                    url: 'auth/login',
                    method: 'POST',
                    body: credentials,
                }),
            }),
            logout: build.mutation<void, void>({
                query: () => ({
                    url: 'auth/logout',
                    method: 'POST',
                }),
                invalidatesTags: ['Users_CurrentUser'],
            }),
        }),
    });
