import { StatisticData } from '@typings';

import { baseApi } from './base.api';

export const statisticsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Statistics_DailyStatsPages',
            'Statistics_CurrentDailyStats',
            'Statistics_MonthlyStatsPages',
            'Statistics_CurrentMonthlyStats',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadDailyStatsPage: build.query<StatisticData[], number>({
                query: (page: number) => ({
                    url: `stats/daily/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_DailyStatsPages'],
            }),
            getCurrentDayStats: build.query<StatisticData, void>({
                query: () => ({
                    url: `stats/daily/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentDailyStats'],
            }),
            loadMonthlyStatsPage: build.query<StatisticData[], number>({
                query: (page: number) => ({
                    url: `stats/monthly/${page}`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_MonthlyStatsPages'],
            }),
            getCurrentMonthStats: build.query<StatisticData, void>({
                query: () => ({
                    url: `stats/monthly/current`,
                    method: 'GET',
                }),
                providesTags: ['Statistics_CurrentMonthlyStats'],
            }),
        }),
    });
