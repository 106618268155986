import { configureStore } from '@reduxjs/toolkit';

import { api, appSliceReducer } from './app';

export const store = configureStore({
    reducer: {
        app: appSliceReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([
        api.middleware,
    ]),
});

export type AppState = ReturnType<typeof store.getState>;

export * from './app';
