import { Column, EColumnAlignment, ERowAlignment, IComponentProps, Row } from '@hyperclap/ui';
import React from 'react';

interface IResponsivePageProps extends IComponentProps {
    /** Optional footer element */
    footer?: React.ReactElement;

    /** Optional header element */
    header?: React.ReactElement;
}

export const DesktopPage = (props: React.PropsWithChildren<IResponsivePageProps>) => {
    const {
        children,
        footer,
        header,
    } = props;

    return (
        <Column grow alignment={EColumnAlignment.STRETCH}>
            <Row>{header}</Row>
            <Row grow alignment={ERowAlignment.STRETCH}>{children}</Row>
            <Row>{footer}</Row>
        </Column>
    );
};
