import { ReactElement } from 'react';

export interface IAuthData {
    authToken: string;
    refreshToken: string;
}

export type TAuthContext = IAuthData & {
    redirectUnauthorizedTo: ReactElement | string;
    clearTokens: () => void;
    updateToken: (accessToken?: string, refreshToken?: string) => void;
}
