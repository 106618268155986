import React from 'react';

import { useAuth } from '@hooks';
import { useLogger } from "@hyperclap/ui";

export const AuthRequired = (props: React.PropsWithChildren) => {
    const { children } = props;
    const { authToken, redirectUnauthorizedTo } = useAuth();

    const logger = useLogger({ target: AuthRequired.name });

    logger.debug(authToken);

    return authToken
        ? children
        : redirectUnauthorizedTo;
};
